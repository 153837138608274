import { Card, Text } from '@anchorage/common/dist/components';
import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import {
  FormCheckbox,
  FormInput,
  FormSelect,
} from '@anchorage/common/dist/components/Form/components';
import type { SelectOption } from '@anchorage/common/dist/components/SelectV2/types';

import css from './styles.module.scss';

type Props = {
  countryOptions: SelectOption<string, string>[];
  isCountryOptionsLoading: boolean;
};

function CompleteYourProfileContent({
  countryOptions,
  isCountryOptionsLoading,
}: Props) {
  const { control } = useFormContext();

  return (
    <div>
      <Card className={css.container}>
        <FormInput
          control={control}
          id="mainUserEmail"
          name="mainUserEmail"
          label="Email"
          type="email"
          required
        />
        <div className={css.sideBySideQuestions}>
          <FormInput
            containerClassName={css.sideBySideQuestion}
            control={control}
            id="mainUserFirstName"
            name="mainUserFirstName"
            label="First name"
            required
          />
          <FormInput
            containerClassName={css.sideBySideQuestion}
            control={control}
            id="mainUserLastName"
            name="mainUserLastName"
            label="Last name"
            required
          />
        </div>
        <FormSelect
          control={control}
          id="mainUserCountry"
          name="mainUserCountry"
          label="Country of residence"
          options={countryOptions}
          isLoading={isCountryOptionsLoading}
          required
        />
      </Card>
      <div className={css.checkboxContainer}>
        <FormCheckbox
          control={control}
          label={
            <Text size="small">I have read and accept the following:</Text>
          }
          name="mainUserCheckbox"
        />
      </div>
      <ul className={css.checkboxLinks}>
        <li>
          <Text<'a'>
            type="link"
            tag="a"
            href="https://www.porto.xyz/unhosted-wallet-privacy-notice"
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          >
            Unhosted wallet terms and conditions
          </Text>
        </li>
        <li>
          <Text<'a'>
            type="link"
            tag="a"
            href="https://www.porto.xyz/legal"
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          >
            {"Porto's legal disclosures"}
          </Text>
        </li>
        <li>
          <Text<'a'>
            type="link"
            tag="a"
            href="https://www.porto.xyz/unhosted-wallet-terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          >
            Unhosted wallet privacy policy
          </Text>
        </li>
      </ul>
    </div>
  );
}

export { CompleteYourProfileContent };
