import { FEATURE_FLAGS } from 'constants/split';
import Head from 'next/head';
import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';

import { useFeatureFlagsContext } from '@anchorage/feature-flags';

import PageContainer from './components/PageContainer/PageContainer';

import useIsPortoApp from 'utils/useIsPortoApp';

import css from './styles.module.scss';

const Signup = () => {
  const router = useRouter();
  const { useIsFeatureActive } = useFeatureFlagsContext();
  const isPorto = useIsPortoApp();
  const [isPortoSignupAvailable] = useIsFeatureActive(
    FEATURE_FLAGS.PORTO_SIGNUP_ENABLED,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const showSignupPage = isPortoSignupAvailable && isPorto;
      if (!showSignupPage) {
        router.push('/404');
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [isPortoSignupAvailable, router, isPorto]);

  if (!isPortoSignupAvailable || !isPorto) {
    return <></>;
  }

  return (
    <>
      <Head>
        <title>Porto - Registration</title>
      </Head>
      <div className={css.page}>
        <PageContainer />
      </div>
    </>
  );
};

export default Signup;
