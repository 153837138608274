import { Button } from '@latitude/button';
import cn from 'classnames';

import { PORTO_SELF_REGISTRATION_PAGES } from 'components/porto/Signup/helpers';

import css from './styles.module.scss';

type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
  setPage: (page: PORTO_SELF_REGISTRATION_PAGES) => void;
  onSubmit: (skipTeamUsers?: boolean) => void;
  isDisabled: boolean;
  isLoading: boolean;
};

function PageButtons({
  page,
  setPage,
  onSubmit,
  isDisabled,
  isLoading,
}: Props) {
  if (page === PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE) {
    return null;
  }

  const isFirstPage =
    page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE;

  const showBackButton =
    page !== PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE;

  const handleBackButton = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS);
    }
  };

  const handleContinueButton = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM) {
      onSubmit();
    }
  };

  const isLastFormPage = page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM;

  return (
    <div
      className={cn({
        [css.container!]: true,
        [css.singleButton!]: !showBackButton,
        [css.doubleButton!]: showBackButton,
        [css.firstPageSingleButton!]: isFirstPage,
      })}
    >
      {showBackButton && (
        <Button type="outline" onClick={handleBackButton} htmlType="button">
          Back
        </Button>
      )}
      <div className="gap-sizing-sys-size-16 flex">
        {isLastFormPage && (
          <Button
            type="outline"
            htmlType="button"
            onClick={() => onSubmit(true)}
            loading={isLastFormPage ? isLoading : false}
          >
            Skip
          </Button>
        )}
        <Button
          htmlType="button"
          onClick={handleContinueButton}
          disabled={isDisabled}
          loading={isLastFormPage ? isLoading : false}
        >
          {isLastFormPage ? 'Submit' : 'Continue'}
        </Button>
      </div>
    </div>
  );
}

export default PageButtons;
