import { useMutation, useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { useCallback, useEffect } from 'react';

import { Text } from '@anchorage/common/dist/components';
import {
  FormProvider,
  useForm,
} from '@anchorage/common/dist/components/Form/_reactHookForm';
import {
  ObjectSchema,
  array,
  bool,
  object,
  string,
} from '@anchorage/common/dist/components/Form/_yup';
import { yupResolver } from '@anchorage/common/dist/components/Form/_yupResolver';
import { PortoLogoIcon } from '@anchorage/common/dist/components/Icons';
import { useSnackbar } from '@anchorage/common/dist/components/Snackbar';
import {
  UIDeveloperError,
  reportError,
} from '@anchorage/common/dist/utils/errors';
import { isNotEmpty } from '@anchorage/common/dist/utils/filters';

import { AddYourTeamContent } from './components/AddYourTeamContent/AddYourTeamContent';
import { CompleteYourProfileContent } from './components/CompleteYourProfileContent/CompleteYourProfileContent';
import { OrganizationDetailsContent } from './components/OrganizationDetailsContent/OrganizationDetailsContent';
import { RegistrationCompleteContent } from './components/RegistrationCompleteContent/RegistrationCompleteContent';
import { PORTO_SELF_REGISTRATION_PAGES } from 'components/porto/Signup/helpers';

import css from './styles.module.scss';

import PageButtons from '../PageButtons/PageButtons';
import PageHeader from '../PageHeader/PageHeader';

export type Country = {
  code: string;
  name: string;
};

type LoginFormValues = {
  mainUserEmail: string;
  mainUserFirstName: string;
  mainUserLastName: string;
  mainUserCountry: string;
  mainUserCheckbox: boolean;
  organizationName: string;
  organizationCountry: string;
  authorizedUser: {
    email: string;
    firstName: string;
    lastName: string;
    country: string;
  }[];
};

export const getLoginLink = () => {
  const environment = window?.env?.SENTRY_ENVIRONMENT;
  if (environment === 'development') {
    return 'https://dashboard.dev.porto.xyz/login/';
  } else if (environment === 'staging') {
    return 'https://dashboard.staging.porto.xyz/login/';
  }
  return 'https://dashboard.porto.xyz/login/';
};

export const COMPLETE_YOUR_PROFILE_FORM_VALIDATION: ObjectSchema<any> = object({
  mainUserEmail: string().email('Invalid email').required('Email is required'),
  mainUserFirstName: string().required('First name is required'),
  mainUserLastName: string().required('Last name is required'),
  mainUserCountry: string().required('Country is required'),
  mainUserCheckbox: bool()
    .oneOf([true], 'Please check')
    .required('Please check.'),
});

export const ORGANIZATION_DETAILS_FORM_VALIDATION: ObjectSchema<any> = object({
  organizationName: string().required('Name is required'),
  organizationCountry: string().required('Country is required'),
});

export const ADD_YOUR_TEAM_FORM_VALIDATION: ObjectSchema<any> = object({
  authorizedUser: array().of(
    object().shape({
      email: string()
        .email('Invalid email')
        .required('Email is required')
        .test(function (value, ctx) {
          // @ts-expect-error Property 'from' does not exist on type 'TestContext<AnyObject>'.
          const formValues = ctx?.from?.[1].value;
          let isDuplicated = false;

          if (formValues) {
            const mainUserEmail = formValues.mainUserEmail;
            const authorizedUsersEmails = formValues.authorizedUser.map(
              ({ email }: { email: string }) => email,
            );
            const hasSameEmailAsMainUser =
              mainUserEmail && mainUserEmail === value;
            const hasMoreThanOneOcurrenceOnAuthotizedUsersEmails =
              authorizedUsersEmails.length &&
              authorizedUsersEmails.filter((email: string) => email === value)
                .length > 1;
            isDuplicated =
              hasSameEmailAsMainUser ||
              hasMoreThanOneOcurrenceOnAuthotizedUsersEmails;
          }

          if (isDuplicated) {
            return this.createError({
              message: 'Email was already used',
              path: ctx.path,
            });
          }

          return true;
        }),
      firstName: string().required('First name is required'),
      lastName: string().required('Last name is required'),
      country: string().required('Country is required'),
    }),
  ),
});

const getPageContent = ({
  page,
  countriesData,
  isLoadingCountriesData,
}: {
  page: PORTO_SELF_REGISTRATION_PAGES;
  countriesData: Country[];
  isLoadingCountriesData: boolean;
}) => {
  const countries = countriesData?.map(({ name }) => ({
    label: name,
    value: name,
  }));

  switch (page) {
    case PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE:
      return (
        <CompleteYourProfileContent
          countryOptions={countries}
          isCountryOptionsLoading={isLoadingCountriesData}
        />
      );
    case PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS:
      return (
        <OrganizationDetailsContent
          countryOptions={countries}
          isCountryOptionsLoading={isLoadingCountriesData}
        />
      );
    case PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE:
      return <RegistrationCompleteContent />;
    case PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM:
      return (
        <AddYourTeamContent
          countryOptions={countries}
          isCountryOptionsLoading={isLoadingCountriesData}
        />
      );
    default:
      return null;
  }
};

const LoginPageMessage = () => (
  <div className={css.footerMessage}>
    <Text size="small">
      {'Already have an account? '}
      <a className={css.footerMessageLink} href={getLoginLink()}>
        Log in
      </a>
    </Text>
  </div>
);

type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
  setPage: (page: PORTO_SELF_REGISTRATION_PAGES) => void;
};

function PageContent({ page, setPage }: Props) {
  const { addSnackbar } = useSnackbar();

  const getResolver = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE) {
      return yupResolver(COMPLETE_YOUR_PROFILE_FORM_VALIDATION);
    }
    if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      return yupResolver(ORGANIZATION_DETAILS_FORM_VALIDATION);
    }
    if (page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM) {
      return yupResolver(ADD_YOUR_TEAM_FORM_VALIDATION);
    }
    return undefined;
  };

  const methods = useForm<LoginFormValues>({
    mode: 'onChange',
    defaultValues: {
      authorizedUser: [{ email: '', firstName: '', lastName: '', country: '' }],
    },
    resolver: getResolver(),
  });

  useEffect(() => {
    // Reset form state when changing pages but keep inputted values.
    // This is needed because when going back to a previous page, the form state is not being reset to just the previous page's values
    methods.reset(undefined, { keepValues: true });
  }, [page, methods]);

  const { isPending: isLoadingCountriesData, data: countriesData } = useQuery({
    queryKey: ['countries'],
    queryFn: () =>
      fetch('/v1/countries')
        .then((res) => res.json())
        .catch((error) => {
          reportError(
            new UIDeveloperError(
              `Error fetching countries from /v1/countries - ${error}`,
              error,
            ),
          );
        }),
  });

  const {
    mutate: finalizePortoRegistration,
    isPending: isFinalizePortoRegistrationLoading,
  } = useMutation({
    mutationFn: (params: Record<string, any>) =>
      fetch('/v1/submit', {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify(params),
      }),
    onSuccess: () => {
      setPage(PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE);
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        text: 'Something went wrong',
        subtext: 'Please try again',
      });
      reportError(
        new UIDeveloperError(
          `Error submitting data to /v1/submit - ${error}`,
          error,
        ),
      );
    },
  });

  const onSubmit = useCallback(
    (data: LoginFormValues) => {
      finalizePortoRegistration({
        org: {
          name: data.organizationName,
          country: data.organizationCountry,
        },
        users: [
          {
            first_name: data.mainUserFirstName,
            last_name: data.mainUserLastName,
            email: data.mainUserEmail,
            country: data.mainUserCountry,
          },
          ...data.authorizedUser.map((user) => ({
            first_name: user.firstName,
            last_name: user.lastName,
            email: user.email,
            country: user.country,
          })),
        ].filter(isNotEmpty),
      });
    },
    [finalizePortoRegistration],
  );

  const isFirstPage =
    page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE;

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={css.container}>
      <PortoLogoIcon className={css.anchorageLogo} />
      <div className={css.contentContainer}>
        {page !== PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE && (
          <PageHeader page={page} />
        )}
        <FormProvider {...methods}>
          {getPageContent({
            page,
            countriesData,
            isLoadingCountriesData,
          })}
        </FormProvider>
      </div>
      <div
        className={cn({ [css.footer!]: true, [css.firstPage!]: isFirstPage })}
      >
        <PageButtons
          page={page}
          setPage={setPage}
          onSubmit={(skipTeamUsers = false) => {
            if (skipTeamUsers) {
              const formData = methods.getValues();
              formData.authorizedUser = [];
              onSubmit(formData);
              return;
            }

            methods.handleSubmit(onSubmit)();
          }}
          isDisabled={!methods?.formState?.isValid}
          isLoading={isFinalizePortoRegistrationLoading}
        />
        {page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE && (
          <LoginPageMessage />
        )}
      </div>
    </form>
  );
}

export default PageContent;
