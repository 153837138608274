import cn from 'classnames';

interface Props {
  className?: string;
}

const registrationCompleteMobile = ({ className }: Props) => {
  const classes = cn({ [className!]: !!className });
  return (
    <svg
      width="204"
      height="151"
      viewBox="0 0 204 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <g clipPath="url(#clip0_1439_47361)">
        <path
          d="M89.3212 42.4354C88.0628 19.3051 68.9137 0.935547 45.4716 0.935547C22.0294 0.935547 2.87479 19.3051 1.61638 42.4354H1.58297V43.1593C1.5607 43.7216 1.54956 44.2896 1.54956 44.8631C1.54956 45.4367 1.56627 45.999 1.58297 46.567V120.791H89.3936V42.4354H89.3268H89.3212Z"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M174.192 98.4414H49.0685V109.21H174.192V98.4414Z"
          fill="#AC96FF"
        />
        <path
          d="M114.718 150.164L191.726 150.102C197.678 150.102 202.506 145.91 202.506 140.052C202.506 134.194 197.678 129.444 191.726 129.444H162.348"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M123.165 150.164H71.926V139.696C71.848 136.216 68.9804 133.393 65.4446 133.393H54.4307C50.8948 133.393 48.0272 130.569 48.0272 127.089C48.0272 123.609 50.8948 120.786 54.4307 120.786H174.877"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M95.101 149.986C110.714 149.986 123.371 136.906 123.371 120.775H66.8257C66.8257 136.906 79.4822 149.986 95.0955 149.986H95.101Z"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M144.329 150.163L158.545 121.37L173.284 150.163H144.329Z"
          fill="white"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M1.71655 69.6758L51.4685 120.296"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M173.657 97.9566H49.6865V27.1068C49.6865 26.0599 50.6665 25.208 51.8804 25.208H171.463C172.677 25.208 173.657 26.0599 173.657 27.1068V97.9511V97.9566Z"
          fill="black"
        />
        <path
          d="M173.657 97.9566H49.6865V27.1068C49.6865 26.0599 50.6665 25.208 51.8804 25.208H171.463C172.677 25.208 173.657 26.0599 173.657 27.1068V97.9511V97.9566Z"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M179.136 128.894C187.768 128.894 194.766 121.896 194.766 113.264C194.766 104.632 187.768 97.6338 179.136 97.6338C170.504 97.6338 163.506 104.632 163.506 113.264C163.506 121.896 170.504 128.894 179.136 128.894Z"
          fill="black"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M64.0749 32.9809C64.7453 32.9809 65.2888 32.4349 65.2888 31.7614C65.2888 31.088 64.7453 30.542 64.0749 30.542C63.4045 30.542 62.861 31.088 62.861 31.7614C62.861 32.4349 63.4045 32.9809 64.0749 32.9809Z"
          fill="white"
        />
        <path
          d="M59.8599 32.9809C60.5303 32.9809 61.0737 32.4349 61.0737 31.7614C61.0737 31.088 60.5303 30.542 59.8599 30.542C59.1895 30.542 58.646 31.088 58.646 31.7614C58.646 32.4349 59.1895 32.9809 59.8599 32.9809Z"
          fill="white"
        />
        <path
          d="M55.6503 32.9809C56.3207 32.9809 56.8641 32.4349 56.8641 31.7614C56.8641 31.088 56.3207 30.542 55.6503 30.542C54.9799 30.542 54.4364 31.088 54.4364 31.7614C54.4364 32.4349 54.9799 32.9809 55.6503 32.9809Z"
          fill="white"
        />
        <path
          d="M116.032 102.043H107.307C106.559 102.043 105.953 102.649 105.953 103.396V103.402C105.953 104.149 106.559 104.755 107.307 104.755H116.032C116.779 104.755 117.385 104.149 117.385 103.402V103.396C117.385 102.649 116.779 102.043 116.032 102.043Z"
          fill="white"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M117.413 25.6145L162.677 67.5319L200.769 5.37402L117.413 25.6145Z"
          fill="#AC96FF"
        />
        <path
          d="M200.552 5.48535L135.265 41.868V65.1375L149.608 55.5769"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M104.344 59.0845L126.817 46.4893"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M114.228 62.9101L126.979 55.7383"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M123.31 65.9337L128.249 63.2275"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
        <path
          d="M72.7946 25.498V97.9237"
          stroke="white"
          strokeWidth="1.21944"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_1439_47361">
          <rect
            width="202.176"
            height="150.447"
            fill="white"
            transform="translate(0.937012 0.329102)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default registrationCompleteMobile;
